import { ArrowBackRounded } from "@mui/icons-material";
import colors from "../../../common/colors";
import styled from "@emotion/styled";

/*Attributes for Circle representing 'Step'*/
export const StyledBackground = styled("div")`
  &.s {
    display: flex;
  }
  margin-right: 0px;
  position: fixed;
  bottom: 5%;
  left: 5%;
  border-radius: 50%;
  height: 3.5rem;
  min-width: 3.5rem;
  align-items: center;
  justify-content: center;
  background-color: ${colors.lavender};
  display: none;
`;

export const StyledArrowBackRounded = styled(ArrowBackRounded)`
  font-size: 2.7rem;
  color: white;
`;
