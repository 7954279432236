import { Container, InputContainer } from "./styles";

import { Button } from "@mui/material";
import Footer from "../Footer";
import Headline from "../Headline";
import React from "react";
import SurveeOnboardingContainer from "../SurveeOnboardingContainer";
import usePortalSize from "../../../../hooks/usePortalSize";

const OptionStepContainer = ({ props, children }) => {
  const { saveAndNext, step, headline, selected, handlePrevStep } = props;
  const buttonLabel = selected ? "Weiter" : "Überspringen";
  const size = usePortalSize();

  return (
    <SurveeOnboardingContainer>
      <Container className={size}>
        <Headline step={step} handlePrevStep={handlePrevStep}>
          {headline}
        </Headline>
        <div>
          <InputContainer>{children}</InputContainer>
        </div>
        <Button
          variant="confirm"
          onClick={() => {
            saveAndNext();
          }}
        >
          {buttonLabel}
        </Button>
      </Container>
      <Footer handlePrevStep={handlePrevStep}></Footer>
    </SurveeOnboardingContainer>
  );
};

export default OptionStepContainer;
