import styled from "@emotion/styled";

export const TextContainer = styled("div")`
  margin-top: 1.5rem;
  margin-bottom: 2rem;
  width: 55%;
  text-align: center;
  overflow-y: auto;
  max-height: 85%;
  padding: 0px 15px;

  &.l {
    width: 60%;
  }
  &.m {
    width: 70%;
  }
  &.s {
    min-width: 87%;
    max-height: 95%;
  }
`;
