import { Text, TextContainer } from "./styles.js";

import CardIntroContentSwitch from "./CardIntroContentSwitch/index.js";
import OnboardingPageContainer from "../OnboardingPageContainer";
import React from "react";

const CardIntroStep = ({ props }) => {
  const { step } = props;

  const isFinalStep = step === 12;
  const buttonLabel = isFinalStep ? "Zum Tool" : "Weiter";

  return (
    <OnboardingPageContainer
      props={{ ...props, headline: "Fast fertig.", buttonLabel }}
    >
      <TextContainer>
        <Text>
          Bevor wir beginnen, lass uns einen Blick auf unsere abgebildete Score
          Karte werfen. Du wirst sie immer wieder in unserem Tool finden:
        </Text>
      </TextContainer>
      <CardIntroContentSwitch step={step} />
    </OnboardingPageContainer>
  );
};

export default CardIntroStep;
