import { ButtonContainer, Container } from "./styles";
import React, { useContext, useState } from "react";

import { Button } from "@mui/material";
import CreationModal from "./CreationModal";
import HistoryContext from "../../../../context/HistoryContext";
import Modal from "react-modal";
import { modalStyle } from "../../../common/modalStyle";
import { useLocation } from "react-router-dom";

const ActionArea = ({ employees, updateTeamList }) => {
  const [isCreateNewModalOpen, setIsCreateNewModalOpen] = useState(false);

  const location = useLocation();

  const { setHistory } = useContext(HistoryContext);
  setHistory(location.pathname);

  return (
    <Container>
      <Modal
        isOpen={isCreateNewModalOpen}
        onRequestClose={() => setIsCreateNewModalOpen(false)}
        style={modalStyle}
        contentLabel="Team Creation Modal"
      >
        <CreationModal
          employees={employees}
          closeModal={() => setIsCreateNewModalOpen(false)}
          updateTeamList={updateTeamList}
        />
      </Modal>
      <ButtonContainer>
        <Button
          variant="contained"
          onClick={() => {
            setIsCreateNewModalOpen(true);
          }}
        >
          Team erstellen
        </Button>
      </ButtonContainer>
    </Container>
  );
};

export default ActionArea;
