import { Cell, Legend, Pie, PieChart, ResponsiveContainer } from "recharts";
import { Container, Header } from "./styles";

import LegendFields from "./LegendFields";
import React from "react";
import colors from "../../../../common/colors";

const COLORS = [
  colors.lavender,
  colors.coral,
  colors.darkGrey,
  colors.midnight,
  colors.lime,
  colors.natural,
];

const DiversityChart = ({
  name,
  data,
  alignLegend = "center",
  verticalAlign = "bottom",
  outerRadius = 60,
}) => {
  data.sort((a, b) => b.value - a.value);
  return (
    <Container>
      <Header>{name}</Header>
      <ResponsiveContainer>
        <PieChart>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            outerRadius={outerRadius}
            fill="#8884d8"
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell
                key={`cell-${index}`}
                fill={COLORS[index % COLORS.length]}
              />
            ))}
          </Pie>
          <Legend
            verticalAlign={verticalAlign}
            align={alignLegend}
            height={100}
            layout="vertical"
            content={LegendFields}
          />
        </PieChart>
      </ResponsiveContainer>
    </Container>
  );
};

export default DiversityChart;
