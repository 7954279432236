import actionsImg from "../../../../../assets/images/cards/actions.png";
import activityImg from "../../../../../assets/images/cards/activity.png";
import areaImg from "../../../../../assets/images/cards/area.png";
import buttonImg from "../../../../../assets/images/cards/button.png";
import commentaryImg from "../../../../../assets/images/cards/commentary.png";
import industryScoreImg from "../../../../../assets/images/cards/industryScore.png";
import scoreImg from "../../../../../assets/images/cards/score.png";

export const cardTutorialContent = [
  {
    infoText:
      'Der Score bezieht sich nur auf die in einem Bereich gegebenen Antworten, hier "Wohlbefinden".',
    img: areaImg,
  },
  {
    infoText:
      "Dein Score ist die Summe aller Pulse-Check-Antworten zu diesem Bereich. Darunter siehst Du den Trend im Vergleich zu den Ergebnissen der letzten zwei Wochen.",
    img: scoreImg,
  },
  {
    infoText:
      "Der Marktdurchschnitt ist der Mittelwert aller Pulse-Check-Antworten unserer Kund*innen zu diesem Bereich. Darunter wird der Trend im Vergleich zu den Ergebnissen der letzten zwei Wochen angezeigt.",
    img: industryScoreImg,
  },
  {
    infoText:
      "Hier wird die Anzahl der Kommentare zu allen Fragen in diesem Bereich angezeigt.",
    img: commentaryImg,
  },
  {
    infoText:
      "Die Antwortrate zeigt das Verhältnis der versendeten Fragen im Vergleich zu den Fragen, die von allen Mitarbeiter*innen beantwortet wurden. Beispiel: Antwortrate 85% = 85 von 100 gesendeten Fragen wurden beantwortet.",
    img: activityImg,
  },
  {
    infoText:
      "Hier kommst Du zu potenziellen Maßnahmen zur Verbesserung des Status Quo in diesem Bereich.",
    img: actionsImg,
  },
  {
    infoText: "Hier kommst Du zu den Scores der Fragen aus diesem Bereich.",
    img: buttonImg,
  },
];
