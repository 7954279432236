import colors from "../../../../../common/colors";
import styled from "@emotion/styled";

export const ContentContainer = styled("div")`
  display: flex;
  width: 100%;
  align-items: center;
  flex-direction: column;
`;

export const CardImage = styled("div")`
  background-image: url(${(props) => props.img});
  background-size: cover;
  background-position: right;
  width: 511px;
  min-height: 241px;
`;

export const Text = styled("p")`
  width: 50%;
  font-size: 14px;
  color: ${colors.lavender};
  font-weight: 500;
  text-align: center;
`;

export const TextContainer = styled("div")`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 140px;
`;
