import styled from "@emotion/styled";

export const FieldContainer = styled("div")`
  &.s {
    max-width: 7cm;
  }
  &.m {
    max-width: 13cm;
  }
  display: flex;
  width: 100%;
  align-items: center;
`;
