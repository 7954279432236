import Dropdown from "../../../common/Dropdown";
import { FieldContainer } from "../../../common/FieldContainerSurveeOnboarding/styles";
import OptionStepContainer from "../OptionStepContainer";
import React from "react";
import buildEmployee from "../../../settings/profile/personal/buildEmployee";
import { sexualityOptions } from "../../../settings/profile/diversityOptions";
import useLightsApiPrivate from "../../../../hooks/useLightsApiPrivate";

const SexualityStep = ({ props }) => {
  const { handleNextStep, step, employee, setEmployee } = props;
  const [sexuality, setSexuality] = React.useState(employee.sexuality);

  const { updateEmployee } = useLightsApiPrivate();

  const saveAndNext = async () => {
    const emp = buildEmployee({ ...employee, sexuality });
    await updateEmployee(employee._id, { ...emp, onboardingStep: "" + step });
    setEmployee({ ...employee, sexuality });
    handleNextStep();
  };

  props = {
    ...props,
    selected: sexuality,
    step,
    headline: "Was ist Deine sexuelle Orientierung?",
    saveAndNext,
  };

  return (
    <OptionStepContainer props={props}>
      <FieldContainer>
        <Dropdown
          value={sexuality ? sexuality : "not_applicable"}
          label="Sexuelle Orientierung"
          menuItems={sexualityOptions}
          onChange={({ target }) => setSexuality(target.value)}
        />
      </FieldContainer>
    </OptionStepContainer>
  );
};

export default SexualityStep;
