import Dropdown from "../../../common/Dropdown";
import { FieldContainer } from "../../../common/FieldContainerSurveeOnboarding/styles";
import OptionStepContainer from "../OptionStepContainer";
import React from "react";
import buildEmployee from "../../../settings/profile/personal/buildEmployee";
import { ethnicityOptions } from "../../../settings/profile/diversityOptions";
import useLightsApiPrivate from "../../../../hooks/useLightsApiPrivate";

const EthnicityStep = ({ props }) => {
  const { handleNextStep, step, employee, setEmployee } = props;
  const [ethnicity, setEthnicity] = React.useState(employee.ethnicity);

  const { updateEmployee } = useLightsApiPrivate();

  const saveAndNext = async () => {
    const emp = buildEmployee({ ...employee, ethnicity });
    await updateEmployee(employee._id, { ...emp, onboardingStep: "" + step });
    setEmployee({ ...employee, ethnicity });
    handleNextStep();
  };

  props = {
    ...props,
    selected: ethnicity,
    step,
    headline: "Welcher ethnischen Herkunft gehörst Du an?",
    saveAndNext,
  };

  return (
    <OptionStepContainer props={props}>
      <FieldContainer>
        <Dropdown
          value={ethnicity ? ethnicity : "not_applicable"}
          label="Ethnische Herkunft"
          menuItems={ethnicityOptions}
          onChange={({ target }) => setEthnicity(target.value)}
        />
      </FieldContainer>
    </OptionStepContainer>
  );
};

export default EthnicityStep;
