import { CardImage, ContentContainer, Text, TextContainer } from "./styles";

import React from "react";

const CardIntroContent = ({ img, infoText }) => {
  return (
    <ContentContainer>
      <CardImage img={img} />
      <TextContainer>
        <Text>{infoText}</Text>
      </TextContainer>
    </ContentContainer>
  );
};

export default CardIntroContent;
