import { CircularProgress } from "@mui/material";
import { Container } from "./styles";
import DiversityChart from "./DiversityChart";
import InactiveChart from "./InactiveChart";
import React from "react";
import { diversityContent } from "../../../../diversityContent";

const ChartContainer = ({ team, loading, largestTeamLength, dimensions }) => {
  if (loading) {
    return <CircularProgress color="primary" />;
  }

  // temporary hack to fix the layout - needs to be refactored
  const ContainerSize = 250 + largestTeamLength * 27;
  const marginTop = 50 - largestTeamLength * 10;

  return (
    <Container height={ContainerSize} marginTop={marginTop}>
      {team.data.map((dimension) => {
        const isActiveDimension = dimensions.includes(dimension.name);

        if (!isActiveDimension) {
          return <InactiveChart name={diversityContent[dimension.name]} />;
        }
        return (
          <DiversityChart
            dimensions={dimensions}
            key={dimension.name}
            name={diversityContent[dimension.name]}
            data={dimension.data}
          />
        );
      })}
    </Container>
  );
};

export default ChartContainer;
