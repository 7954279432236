import Joi from "joi";
import { now } from "moment";

const newPasswordSchema = Joi.object().keys({
  userName: Joi.string().required().messages({
    "string.empty": `Bitte fülle alle Felder aus`,
    "any.required": `Leider besteht für diese Login-Daten kein Account`,
  }),
  recoveryPassword: Joi.string().required().messages({
    "string.empty": `Bitte fülle alle Felder aus`,
    "any.required": `Bitte fülle alle Felder aus`,
  }),
  newPassword: Joi.string().min(8).required().messages({
    "string.empty": `Bitte fülle alle Felder aus`,
    "string.min": `Das Passwort sollte mindestens eine Länge von {#limit} Zeichen haben`,
  }),
  confirmPassword: Joi.string().required().messages({
    "any.required": `Bitte fülle alle Felder aus`,
  }),
});

const emailSchema = Joi.object().keys({
  mail: Joi.string()
    .email({ tlds: { allow: false } })
    .required(),
});

const loginSchema = Joi.object().keys({
  userName: Joi.string().required().messages({
    "string.empty": `Bitte fülle alle Felder aus`,
    "any.required": `Leider besteht für diese Login-Daten kein Account`,
  }),
  password: Joi.string().required().messages({
    "string.empty": `Bitte fülle alle Felder aus`,
    "string.min": `Das Passwort sollte mindestes eine Länge von {#limit} Zeichen haben`,
  }),
});

const nameRegex =
  /^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]+$/u;

const profileFormSchema = Joi.object().keys({
  firstName: Joi.string().regex(nameRegex).required().messages({
    "string.empty": `Bitte fülle alle Felder aus`,
    "string.firstName": `Bitte fülle alle Felder aus`,
    "object.regex": `der Vorname ist ungültig`,
    "string.pattern.base": `der Vorname ist ungültig`,
    "any.required": `der Vorname ist ungültig`,
  }),
  lastName: Joi.string().regex(nameRegex).required().messages({
    "string.empty": `Bitte fülle alle Felder aus`,
    "object.regex": `der Nachname ist ungültig`,
    "string.pattern.base": `der Nachname ist ungültig`,
    "any.required": `Der Nachname ist ungültig`,
  }),
  // TODO only accept mail format - .email({ tlds: { allow: false } }) auch im backend
  mail: Joi.string()
    .email({ tlds: { allow: false } })
    .allow(null, "")
    .messages({
      "string.empty": `Bitte fülle alle Felder aus`,
      "string.email": `Die E-Mail-Adresse ist ungültig`,
    }),
  department: Joi.string().allow(null, ""),
  hadParentingTime: Joi.boolean(),
  role: Joi.string().required().messages({
    "string.empty": `Bitte füge eine Rolle hinzu`,
    "any.required": `Bitte füge eine Rolle hinzu`,
  }),
  gender: Joi.string().allow(null, ""),
  birthdate: Joi.date()
    .greater(new Date(1900, 0, 0, 0, 0, 0, 0))
    .less(now())
    .messages({
      "date.base": `Bitte füge ein Geburtsdatum hinzu`,
      "date.greater": `Bitte füge ein valides Geburtsdatum hinzu`,
      "date.less": `Bitte füge ein valides Geburtsdatum hinzu`,
      "string.empty": `Bitte füge ein Geburtsdatum hinzu`,
      "any.required": `Bitte füge ein Geburtsdatum hinzu`,
    })
    .allow(null, ""),
  level: Joi.string().allow(null, ""),
  pronouns: Joi.array().items(Joi.string()),
  sexuality: Joi.string().allow(null, ""),
  ethnicity: Joi.string().allow(null, ""),
  religion: Joi.string().allow(null, ""),
  disabilities: Joi.array().items(Joi.string()),
  nationality: Joi.string().allow(null, ""),
  social_background: Joi.string().allow(null, ""),
  verified: Joi.boolean().allow(true, ""),
});

const validateNewPwCreds = (creds) => {
  return newPasswordSchema.validate(creds);
};

const validateEmail = (creds) => {
  return emailSchema.validate(creds);
};

const validateLogin = (creds) => {
  return loginSchema.validate(creds);
};

const validateProfile = (creds) => {
  const validationResult = profileFormSchema.validate(creds);
  return validationResult;
};

const validateTwofaCode = (code) => {
  const validationResult = Joi.string().required().validate(code);
  return validationResult;
};

export {
  validateNewPwCreds,
  validateEmail,
  validateLogin,
  validateProfile,
  validateTwofaCode,
};
