import colors from "../../common/colors";
import styled from "@emotion/styled";

export const Container = styled("div")`
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  width: 100%;
  flex-direction: column;
`;

export const DropdownContainer = styled("div")`
  z-index: 5;
  position: relative;
  width: 20%;
  position: left;
  align-self: flex-start;
  height: 60px;
  margin-bottom: 24px;
`;

export const InfoText = styled("div")`
  font-size: 20px;
  font-weight: 500;
  color: ${colors.darkGrey};
  margin-top: 40px;
  margin-bottom: 40px;
`;

export const DateText = styled("p")`
  font-size: 16px;
  font-weight: 500;
  color: ${colors.darkGrey};
`;
