import * as Sentry from "@sentry/react";

import {
  Activity,
  AreaTitle,
  CommentHint,
  CommentaryContainer,
  Content,
  ContentContainer,
  DataContainer,
  LoadingContainer,
  QuestionCardContainer,
  QuestionContainer,
  QuestionStats,
  Score,
  ScoreContainer,
  Trend,
  TrendContainer,
} from "./styles";
import React, { useState } from "react";

import AreaIcon from "../../../../report/AreaIcon";
import { ReactComponent as ArrowDownIcon } from "../../../../../icons/trend_arrow_down_right.svg";
import { ReactComponent as ArrowUpIcon } from "../../../../../icons/trend_arrow_up_right.svg";
import CommentModal from "../../CommentModal/CommentModal";
import LoadingSection from "../../../../scores/ScoreCard/LoadingSection";
import Modal from "react-modal";
import Tooltip from "../../../../common/Tooltip";
import colors from "../../../../common/colors";
import { modalStyle } from "../../../../common/modalStyle";
import useLightsApiPrivate from "../../../../../hooks/useLightsApiPrivate";

export const computeTrendColor = (trend) => {
  switch (true) {
    case parseFloat(trend) < 0:
      return `${colors.red}`;
    case parseFloat(trend) > 0:
      return `${colors.green}`;
    default:
      return `${colors.midnight}`;
  }
};

const QuestionCard = ({ question, areaName, areaIdentifier, index }) => {
  const { patchQuestionCommentsRead } = useLightsApiPrivate();
  const {
    content,
    ratingCombined: score,
    trendRatingCombined: trend,
    activity,
    answersLeft,
    comments,
    read,
    id,
    questionStats,
  } = question;

  const stillLoading = score === null;
  const hasComment = comments.length > 0;
  const kommentarText = comments.length === 1 ? " Kommentar" : " Kommentare";
  const [isModalOpen, setModalOpen] = useState(false);
  const [newComments, setNewComments] = useState(!read);

  const closeModal = () => {
    setModalOpen(false);
  };

  const openModal = async () => {
    setModalOpen(true);
    if (newComments) {
      setNewComments(false);
      try {
        await patchQuestionCommentsRead(id);
      } catch (err) {
        Sentry.captureException(err);
      }
    }
  };

  const { answersAmount, askedQuestionsAmount } = questionStats;

  if (stillLoading) {
    return (
      <QuestionCardContainer>
        <ContentContainer>
          <QuestionContainer>
            <AreaIcon type={areaIdentifier} width={100} />
            <AreaTitle>{areaName}</AreaTitle>
            <Content>{content}</Content>
          </QuestionContainer>
          <CommentHint
            hasComment={false}
            newComments={false}
            data-tip
            data-for={id}
          >
            <Tooltip id={id} padding="400px">
              Kommentare werden erst angezeigt, sobald ein Score sichtbar wird.
            </Tooltip>
            {comments.length}
            {kommentarText}
          </CommentHint>
        </ContentContainer>
        <DataContainer>
          <LoadingContainer>
            <LoadingSection answersLeft={answersLeft} id={`index ${index}`} />
          </LoadingContainer>
        </DataContainer>
      </QuestionCardContainer>
    );
  }

  return (
    <QuestionCardContainer>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        contentLabel="Comment Modal"
        style={modalStyle}
      >
        <CommentModal
          comments={comments}
          question={content}
          close={() => setModalOpen(false)}
        ></CommentModal>
      </Modal>
      <ContentContainer>
        <QuestionContainer>
          <AreaIcon type={areaIdentifier} width={100} />
          <AreaTitle>{areaName}</AreaTitle>
          <Content>{content}</Content>
        </QuestionContainer>
        <CommentaryContainer onClick={() => hasComment && openModal()}>
          <CommentHint hasComment={hasComment} newComments={newComments}>
            {comments.length}
            {kommentarText}
          </CommentHint>
          <QuestionStats>
            {answersAmount} von {askedQuestionsAmount} beantwortet
          </QuestionStats>
        </CommentaryContainer>
      </ContentContainer>
      <DataContainer>
        <ScoreContainer>
          <Score score={score}>{score}</Score>
          <TrendContainer>
            <Trend trend={trend}>{trend}</Trend>
            {trend !== "0.0" &&
              (trend > 0 ? (
                <ArrowUpIcon width={13} stroke={computeTrendColor(trend)} />
              ) : (
                <ArrowDownIcon width={13} stroke={computeTrendColor(trend)} />
              ))}
          </TrendContainer>
        </ScoreContainer>
        <Activity>Antwortrate {activity} %</Activity>
      </DataContainer>
    </QuestionCardContainer>
  );
};

export default QuestionCard;
