import Dropdown from "../../../../../common/Dropdown";
import FieldContainer from "../../../../../common/FieldContainer";
import React from "react";
import { ethnicityOptions } from "../../../diversityOptions";

const EthnicityInput = ({ props }) => {
  const { employee, setEmployee, dynamicSize } = props;
  return (
    <FieldContainer>
      <Dropdown
        size={dynamicSize}
        value={employee.ethnicity}
        label="Ethnische Herkunft"
        menuItems={ethnicityOptions}
        onChange={({ target }) =>
          setEmployee({ ...employee, ethnicity: target.value })
        }
      />
    </FieldContainer>
  );
};

export default EthnicityInput;
