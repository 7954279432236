import "react-toggle/style.css";

import * as Sentry from "@sentry/react";

import {
  ActivateButton,
  AdminInfoText,
  ButtonContainer,
  Container,
  DeactivateText,
  InputContainer,
  InviteButtonContainer,
  ModalTitle,
  NameContainer,
  StatusContainer,
  StyledFormControl,
  StyledTextField,
  SubHeader,
  TitleContainer,
} from "./styles";
import { InputLabel, MenuItem, Select } from "@mui/material";
import React, { useState } from "react";

import Button from "@mui/material/Button";
import DividerHorizontal from "../../../common/DividerHorizontal";
import { Flex } from "@react-spectrum/layout";
import Modal from "react-modal";
import StatusChip from "../../../common/StatusChip";
import StatusInfoText from ".././StatusInfoText";
import { modalStyle } from "../../../common/modalStyle";
import unifyMailFormat from "../../../../utils/unifyMailFormat";
import { useAlert } from "react-alert";
import useLightsApiPrivate from "../../../../hooks/useLightsApiPrivate";
import { useNavigate } from "react-router-dom";
import { validateProfile } from "../../../login/formValidation";

const ProfileEditForm = ({ employee }) => {
  const alert = useAlert();
  const navigate = useNavigate();

  const [isModalOpen, setModalOpen] = useState(false);

  const [role, setRole] = useState(employee.role);
  const [firstName, setFirstName] = useState(employee.firstName);
  const [lastName, setLastName] = useState(employee.lastName);
  const [mail, setMail] = useState(employee.mail);
  const [userId] = useState(employee.userId);
  const [status, setStatus] = useState(employee.status);
  const [buttonInactive, setButtonInactive] = useState(false);

  const isDeactivated = status === "deactivated";

  const {
    updateEmployee,
    deleteEmployee,
    postEmployeeInvite,
    postEmployeeReminder,
    deactivateEmployee,
    reactivateEmployee,
  } = useLightsApiPrivate();

  const handleDelete = async () => {
    try {
      await deleteEmployee(employee._id);
      closeModal();
      alert.success("Teammitglied erfolgreich entfernt.");
      navigate("/settings/users");
    } catch (err) {
      Sentry.captureException(err);
      closeModal();
      alert.error("Es gab ein Problem. Bitte versuche es später erneut.");
    }
  };

  const handleDeactivate = async () => {
    try {
      const updatedEmployee = await deactivateEmployee(employee._id);

      setStatus(updatedEmployee.status);
      alert.success("Mitglied deaktiviert.");
    } catch (err) {
      Sentry.captureException(err);
      if (!err?.response) {
        alert.error("Der Server antwortet nicht.");
      } else if (err.response?.status === 400) {
        alert.error("Das Mitglied ist bereits deaktiviert.");
      }
    }
  };

  const handleReactivate = async () => {
    try {
      const updatedEmployee = await reactivateEmployee(employee._id);

      setStatus(updatedEmployee.status);
      alert.success("Mitglied aktiviert.");
    } catch (err) {
      Sentry.captureException(err);
      if (!err?.response) {
        alert.error("Der Server antwortet nicht.");
      } else if (err.response?.status === 400) {
        alert.error("Das Mitglied ist bereits aktiviert.");
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setButtonInactive(true);

    const newEmployee = {
      firstName: firstName.trim(),
      lastName: lastName.trim(),
      role,
      mail: unifyMailFormat(mail),
    };

    const { error } = validateProfile(newEmployee);
    if (error) {
      alert.removeAll();
      alert.show(error.details[0].message);
      setButtonInactive(false);
      return;
    }

    try {
      alert.removeAll();
      await updateEmployee(employee._id, newEmployee);
      alert.success("Update erfolgreich.");
      navigate("/settings/users");
    } catch (err) {
      Sentry.captureException(err);
      if (!err?.response) {
        alert.error("Der Server antwortet nicht.");
      } else if (err.response?.status === 400) {
        alert.error("Die Daten sind invalide.");
      } else if (err.response?.status === 404) {
        alert.error("Not Found");
      } else if (err.response?.status === 406) {
        alert.error(err.response.data.message);
      } else if (err.response?.status === 409) {
        alert.error(
          "Es existiert schon ein Mitglied mit dieser E-Mail-Adresse."
        );
      } else {
        alert.error("Es gab ein Problem. Bitte versuche es später erneut.");
      }
    }
    setButtonInactive(false);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const showInvitationButton =
    (status === "created" || status === "invited") && employee.mail;
  const buttonText =
    status === "created" ? "Einladung senden" : "Erinnerung versenden";

  const handleInviteOrReminder = async () => {
    try {
      if (status === "created") {
        postEmployeeInvite(employee._id);
        setStatus("invited");
        alert.success("Einladung gesendet");
      } else if (status === "invited") {
        postEmployeeReminder(employee._id);
        alert.success("Erinnerung gesendet");
      }
    } catch (err) {
      Sentry.captureException(err);
      alert.error("Fehler aufgetreten");
    }
  };

  return (
    <Container onSubmit={handleSubmit}>
      <Modal
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        style={modalStyle}
        contentLabel="Profile Deletion Modal"
      >
        <ModalTitle>
          Bist Du sicher, dass Du diese*n Mitarbeiter*in aus der App entfernen
          willst?
        </ModalTitle>
        <ButtonContainer>
          <Button onClick={closeModal} variant="neutral">
            Abbrechen
          </Button>
          <Button onClick={handleDelete} variant="warning">
            Löschen
          </Button>
        </ButtonContainer>
      </Modal>
      <TitleContainer>
        <SubHeader>Status</SubHeader>
      </TitleContainer>
      <StatusContainer>
        <StatusChip status={status} />
        <StatusInfoText status={status} mail={employee.mail} />
      </StatusContainer>
      <Flex justifyContent={"start"}>
        <InviteButtonContainer>
          {showInvitationButton && (
            <Button
              variant="neutral"
              isLoading={buttonInactive}
              onClick={handleInviteOrReminder}
            >
              {buttonText}
            </Button>
          )}
          {isDeactivated && (
            <ActivateButton
              variant="outlined"
              color="success"
              isLoading={buttonInactive}
              onClick={handleReactivate}
            >
              Aktivieren
            </ActivateButton>
          )}
        </InviteButtonContainer>
      </Flex>
      <DividerHorizontal />
      <TitleContainer>
        <SubHeader>Persönliche Information</SubHeader>
      </TitleContainer>
      <InputContainer>
        <NameContainer>
          <StyledTextField
            defaultValue={firstName}
            autoComplete="false"
            onChange={(event) => setFirstName(event.target.value)}
            id="outlined-basic"
            variant="outlined"
            label="Vorname"
            disabled={isDeactivated}
          />
        </NameContainer>
        <NameContainer>
          <StyledTextField
            defaultValue={lastName}
            autoComplete="false"
            onChange={(event) => setLastName(event.target.value)}
            id="outlined-basic"
            variant="outlined"
            label="Nachname"
            disabled={isDeactivated}
          />
        </NameContainer>
      </InputContainer>
      <InputContainer>
        <NameContainer>
          <StyledTextField
            defaultValue={mail}
            autoComplete="false"
            onChange={(event) => setMail(event.target.value)}
            id="outlined-basic"
            variant="outlined"
            label="E-Mail"
            disabled={isDeactivated}
          />
        </NameContainer>
        <NameContainer>
          <StyledTextField
            defaultValue={userId}
            autoComplete="false"
            disabled
            id="outlined-basic"
            variant="outlined"
            label="Benutzername"
          />
        </NameContainer>
      </InputContainer>
      <DividerHorizontal />
      {employee.role !== "super admin" && (
        <>
          <TitleContainer>
            <SubHeader>Zugriff</SubHeader>
          </TitleContainer>
          <StyledFormControl>
            <InputLabel id="demo-simple-select-label">Rolle</InputLabel>
            <Select
              defaultValue={role}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={role}
              label="Rolle"
              onChange={(e) => setRole(e.target.value)}
              disabled={isDeactivated}
            >
              <MenuItem value={"admin"}>Admin</MenuItem>
              <MenuItem value={"survee"}>Survee</MenuItem>
            </Select>
          </StyledFormControl>
          <AdminInfoText>
            Ein Admin erhält vollen Zugriff auf das Tool.
          </AdminInfoText>
        </>
      )}
      <ButtonContainer>
        <Button
          variant={isDeactivated ? "contained" : "neutral"}
          isLoading={buttonInactive}
          disabled={isDeactivated}
          type="submit"
        >
          Speichern
        </Button>
        {employee.role !== "super admin" && (
          <Button onClick={() => setModalOpen(true)} variant="warning">
            Entfernen
          </Button>
        )}
      </ButtonContainer>
      {!isDeactivated && (
        <>
          <DividerHorizontal />
          <TitleContainer>
            <SubHeader>Mitglied deaktivieren</SubHeader>
          </TitleContainer>
          <DeactivateText>
            Wenn ein Mitglied deaktiviert ist, erhält es keine Pulse-Checks oder
            E-Mail-Benachrichtigungen.{" "}
          </DeactivateText>
          <ButtonContainer>
            <Button onClick={handleDeactivate} variant="warning">
              Deaktivieren
            </Button>
          </ButtonContainer>
        </>
      )}
    </Container>
  );
};

export default ProfileEditForm;
