import styled from "@emotion/styled";

export const Container = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 4rem;
  max-height: 80%;
  &.m {
    margin-top: 2rem;
    max-width: 90%;
  }
  &.s {
    margin-top: 2rem;
    max-width: 90%;
  }
`;

export const InputContainer = styled("div")`
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
  align-items: center;
`;
