import { BackButtonContainer, Container, Heading, Step } from "./styles";

import { ArrowBackRounded } from "@mui/icons-material";
import Heading1 from "../../../common/Heading1";
import React from "react";
import usePortalSize from "../../../../hooks/usePortalSize";

const Headline = ({ step, children, handlePrevStep }) => {
  const size = usePortalSize();
  return (
    <Container className={size}>
      <BackButtonContainer className={size}>
        <ArrowBackRounded onClick={() => handlePrevStep()} color={"primary"} />
      </BackButtonContainer>
      <Step className={size}>
        <Heading>{step}</Heading>
      </Step>
      <Heading1>{children}</Heading1>
    </Container>
  );
};

export default Headline;
