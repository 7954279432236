import colors from "../../../../common/colors";
import styled from "@emotion/styled";

export const Container = styled("div")`
  margin-top: -100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
  height: 300px;
  justify-content: center;
`;

export const Header = styled("div")`
  display: flex;
  flex-direction: column;
  height: 10px;
  text-align: center;
  font-weight: 500;
  color: ${colors.darkGrey};
  font-size: 16px;
`;

export const InactiveCircle = styled("div")`
  margin-top: 30px;
  margin-bottom: 135px;
  background-color: ${colors.darkGrey};
  border-radius: 100px;
  height: 120px;
  width: 120px;
`;
