import { StyledArrowBackRounded, StyledBackground } from "./styles";

import React from "react";
import usePortalSize from "../../../../hooks/usePortalSize";

const Footer = ({ handlePrevStep }) => {
  const size = usePortalSize();
  return (
    <StyledBackground className={size}>
      <StyledArrowBackRounded onClick={() => handlePrevStep()} />
    </StyledBackground>
  );
};

export default Footer;
