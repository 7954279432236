import { Container, Header, InactiveCircle } from "./styles";

import React from "react";
import Tooltip from "../../../../common/Tooltip";
const hoverText = "Du erhebst zu diesen Dimensionen keine Daten.";

const InactiveChart = ({ name }) => {
  return (
    <Container>
      <Header>{name}</Header>
      <InactiveCircle data-tip data-for="inactive-tooltip" />
      <Tooltip id="inactive-tooltip">{hoverText}</Tooltip>
    </Container>
  );
};

export default InactiveChart;
