import { Text, TextContainer } from "./styles.js";

import { Button } from "@mui/material";
import OnboardingPageContainer from "../OnboardingPageContainer/index.js";
import React from "react";

const WelcomeStep = ({ props }) => {
  const { handleNextStep } = props;
  return (
    <OnboardingPageContainer
      props={{
        ...props,
        headline:
          "Für gelebte Diversität, Gleichstellung & Inklusion in Organisationen.",
      }}
    >
      <TextContainer>
        <Text>Herzlich Willkommen!</Text>
        <Text>Wir freuen uns, Dich an Bord zu haben 🚀</Text>
        <Text>
          Unser Ziel ist es, Vielfalt und ein vorurteilsfreies Miteinander in
          Organisationen durch Transparenz zu fördern - für eine bessere
          Gemeinschaft und eine effizientere Organisation.
        </Text>
        <Text>Wenn Du Fragen hast, zögere nicht, uns zu kontaktieren.</Text>
        <Text>Viel Spaß!</Text>
      </TextContainer>
      <Button variant="confirm" onClick={handleNextStep}>
        Start Onboarding
      </Button>
    </OnboardingPageContainer>
  );
};

export default WelcomeStep;
