import styled from "@emotion/styled";

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: fit-content;
  max-width: 300px;
  width: 90%;
  margin-left: 30px;
  margin-right: 30px;
`;

export const TitleContainer = styled("div")`
  align-self: center;
`;

export const AdminInfoText = styled("p")`
  margin-top: 0;
  font-size: 14px;
  text-align: center;

  p {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    max-width: 300px;
    word-break: break-all;
    overflow: hidden;
  }
`;

export const ButtonContainer = styled("div")`
  margin-top: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const Subheader = styled("h3")`
  font-weight: 500;
  font-size: 16px;
`;
