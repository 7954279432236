export const BIRTHDAY_STEP_TITLE = "birthdate";
export const DISABILITY_STEP_TITLE = "disabilities";
export const ETHNICITY_STEP_TITLE = "ethnicity";
export const GENDER_STEP_TITLE = "gender";
export const PARENTAL_LEAVE_STEP_TITLE = "parentalLeave";
export const RELIGION_STEP_TITLE = "religion";
export const SEXUALITY_STEP_TITLE = "sexuality";
export const SOCIAL_BACKGROUND_STEP_TITLE = "social_background";
export const WELCOME_STEP_TITLE = "surveeWelcome";
export const THANK_YOU_STEP_TITLE = "thankYou";
