import * as Sentry from "@sentry/react";

import {
  ButtonContainer,
  Container,
  InputContainer,
  StyledText,
  SubHeader,
  TitleContainer,
} from "./styles";
import React, { useState } from "react";

import { Button } from "@mui/material";
import DividerHorizontal from "../../../../common/DividerHorizontal";
import { PARENTAL_LEAVE_STEP_TITLE } from "../../../../onboarding/SurveeOnboarding/steps/stepTitles";
import StaticFields from "./StaticFields";
import { inputFields } from "./DynamicFields/fields";
import { useAlert } from "react-alert";
import useAuth from "../../../../../hooks/useAuth";
import useLightsApiPrivate from "../../../../../hooks/useLightsApiPrivate";
import usePortalSize from "../../../../../hooks/usePortalSize";
import { validateProfile } from "../../../../login/formValidation";

const PersonalProfile = ({ employee, setEmployee, dimensionOptions }) => {
  const windowSize = usePortalSize();
  const [buttonInactive, setButtonInactive] = useState(false);

  const { updateEmployee } = useLightsApiPrivate();

  const alert = useAlert();
  const { auth } = useAuth();

  const isSurvee = auth.role === "survee";
  const dynamicSize = windowSize === "s" ? "small" : "medium";

  const availableInputs = [...dimensionOptions, PARENTAL_LEAVE_STEP_TITLE];
  const amountInputRows = Math.ceil(availableInputs.length / 2);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const employeeUpdate = {
      firstName: employee.firstName.trim(),
      lastName: employee.lastName.trim(),
      mail: employee.mail?.trim() || undefined,
      pronouns: employee.pronouns,
      sexuality: employee.sexuality,
      religion: employee.religion,
      ethnicity: employee.ethnicity,
      birthdate: employee.birthdate,
      nationality: employee.nationality,
      disabilities: employee.disabilities,
      hadParentingTime: employee.hadParentingTime,
      role: employee.role,
      gender: employee.gender,
      social_background: employee.social_background,
      verified: employee.verified,
    };

    const { error } = validateProfile(employeeUpdate);

    if (error) {
      alert.removeAll();
      alert.show(error.details[0].message);
      setButtonInactive(false);

      return;
    }

    try {
      alert.removeAll();
      await updateEmployee(employee._id, employeeUpdate);

      alert.success("Update erfolgreich.");
    } catch (err) {
      Sentry.captureException(err);
      if (!err?.response) {
        alert.error("Der Server antwortet nicht.");
      } else if (err.response?.status === 400) {
        alert.error("Die Daten sind invalide.");
      } else if (err.response?.status === 404) {
        alert.error("Not Found");
      } else if (err.response?.status === 409) {
        alert.error(
          "Es existiert schon ein Mitglied mit dieser E-Mail-Adresse."
        );
      } else {
        alert.error("Es gab ein Problem. Bitte versuche es später erneut.");
      }
    }
    setButtonInactive(false);
  };

  const updateDisabilities = (disabilities) => {
    if (
      (disabilities[0] === "not_applicable" ||
        disabilities[0] === "no_disability") &&
      disabilities.length > 1
    ) {
      setEmployee({ ...employee, disabilities: disabilities.slice(1) });
    } else if (disabilities.includes("not_applicable")) {
      setEmployee({ ...employee, disabilities: ["not_applicable"] });
    } else if (disabilities.includes("no_disability")) {
      setEmployee({ ...employee, disabilities: ["no_disability"] });
    } else {
      setEmployee({ ...employee, disabilities });
    }
  };

  const personalInfoText = isSurvee
    ? "Diese Informationen werden von Deiner Personalabteilung verwaltet. Bei fehlerhaften Angaben, melde Dich bei bei Deiner Ansprechperson."
    : "Diese Informationen sind für alle Admins einsehbar und können in der Mitgliederverwaltung bearbeitet werden.";

  const props = { employee, setEmployee, dynamicSize, updateDisabilities };
  return (
    <Container onSubmit={handleSubmit}>
      <TitleContainer className={windowSize}>
        <SubHeader>Persönliche Daten</SubHeader>
        <StyledText className={windowSize} align="start">
          {personalInfoText}
        </StyledText>
      </TitleContainer>
      <StaticFields employee={employee} />
      <DividerHorizontal />
      <TitleContainer className={windowSize}>
        <SubHeader>Sensible Daten</SubHeader>
        <StyledText className={windowSize} align="start">
          Diese Informationen kannst nur Du bearbeiten und einsehen. Alle Felder
          werden anonymisiert verarbeitet.
        </StyledText>
      </TitleContainer>
      {Array.from({ length: amountInputRows }).map((_, i) => {
        const flexDirection = i === amountInputRows - 1 ? "column" : "row";
        return (
          <InputContainer
            className={windowSize}
            key={i}
            flexDirection={flexDirection}
          >
            {availableInputs.slice(i * 2, i * 2 + 2).map((input) => {
              return inputFields
                .find((field) => field.title === input)
                .component(props);
            })}
          </InputContainer>
        );
      })}
      <ButtonContainer className={windowSize}>
        <Button variant="neutral" type="submit" isLoading={buttonInactive}>
          Speichern
        </Button>
      </ButtonContainer>
    </Container>
  );
};

export default PersonalProfile;
