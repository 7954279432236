import styled from "@emotion/styled";

export const Container = styled("div")`
  display: flex;
  align-items: center;
  height: ${(props) => props.height}px;
  margin-top: ${(props) => props.marginTop}px;
  width: 95%;
  margin-left: 20px;
`;
