import {
  DropdownContainer,
  ScheduleBody,
  Text,
  TextContainer,
} from "./styles.js";
import React, { useEffect, useState } from "react";

import Dropdown from "../../../settings/schedule/schedule/Dropdown";
import OnboardingPageContainer from "../OnboardingPageContainer/index.js";
import colors from "../../../common/colors.js";
import useLightsApiPrivate from "../../../../hooks/useLightsApiPrivate.js";

const ScheduleStep = ({ props }) => {
  const [loading, setLoading] = useState(true);
  const [schedule, setSchedule] = useState([]);

  const { getAccount } = useLightsApiPrivate();

  useEffect(() => {
    if (loading) {
      getAccount().then((data) => {
        if (data.schedule) setSchedule(data.schedule);
        setLoading(false);
      });
    }
  });

  const findHourByWeekday = (weekday) => {
    const dayTime = schedule.find((dayTime) => dayTime.weekday === weekday);
    if (dayTime) return dayTime.hour;

    return null;
  };

  return (
    <OnboardingPageContainer
      props={{
        ...props,
        headline: "Plane Deine Pulse Checks.",
        buttonLabel: "Weiter",
      }}
    >
      <TextContainer>
        <Text>Wähle den Tag und die Uhrzeit.</Text>
        <Text>
          Wir empfehlen, mindestens zweimal pro Woche eine Frage zu versenden.
        </Text>
        <Text>
          Die besten Antwortzeiten sind in der Regel morgens gegen 9 Uhr.
        </Text>
      </TextContainer>
      <ScheduleBody>
        <DropdownContainer zIndex={3}>
          <Dropdown
            day={"Montag"}
            dayIndex={1}
            curHour={findHourByWeekday(1)}
            labelColor={colors.midnight}
          />
        </DropdownContainer>
        <DropdownContainer zIndex={3}>
          <Dropdown
            day={"Dienstag"}
            dayIndex={2}
            curHour={findHourByWeekday(2)}
            labelColor={colors.midnight}
          />
        </DropdownContainer>
        <DropdownContainer zIndex={3}>
          <Dropdown
            day={"Mittwoch"}
            dayIndex={3}
            labelColor={colors.midnight}
            curHour={findHourByWeekday(3)}
          />
        </DropdownContainer>
        <DropdownContainer zIndex={2}>
          <Dropdown
            day={"Donnerstag"}
            labelColor={colors.midnight}
            dayIndex={4}
            curHour={findHourByWeekday(4)}
          />
        </DropdownContainer>
        <DropdownContainer zIndex={2}>
          <Dropdown
            day={"Freitag"}
            dayIndex={5}
            curHour={findHourByWeekday(5)}
            labelColor={colors.midnight}
          />
        </DropdownContainer>
        <DropdownContainer zIndex={2}>
          <Dropdown
            day={"Samstag"}
            dayIndex={6}
            curHour={findHourByWeekday(6)}
            labelColor={colors.midnight}
          />
        </DropdownContainer>
        <DropdownContainer>
          <Dropdown
            day={"Sonntag"}
            dayIndex={0}
            curHour={findHourByWeekday(0)}
            labelColor={colors.midnight}
          />
        </DropdownContainer>
      </ScheduleBody>
    </OnboardingPageContainer>
  );
};

export default ScheduleStep;
