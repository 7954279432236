import {
  AdminInfoText,
  ButtonContainer,
  Container,
  Subheader,
  TitleContainer,
} from "./styles";

import Button from "@mui/material/Button";
import Modal from "react-modal";
import React from "react";
import { modalStyle } from "../../../common/modalStyle";

// im not sure if this is the best practice

const RedirectModal = ({ isOpen, onRequestClose, onContinue, link }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      style={modalStyle}
      contentLabel="Redirect Modal"
    >
      <Container>
        <TitleContainer>
          <Subheader>Du verlässt die Diverlyze App.</Subheader>
        </TitleContainer>
        <AdminInfoText>
          Der folgende Link wird geöffnet
          <p>{link}</p>
        </AdminInfoText>
        <ButtonContainer>
          <Button variant="outlined" onClick={onRequestClose}>
            Abbrechen
          </Button>
          <Button variant="confirm" onClick={onContinue}>
            Weiter
          </Button>
        </ButtonContainer>
      </Container>
    </Modal>
  );
};

export default RedirectModal;
