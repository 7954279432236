import Dropdown from "../../../common/Dropdown";
import { FieldContainer } from "../../../common/FieldContainerSurveeOnboarding/styles";
import OptionStepContainer from "../OptionStepContainer";
import React from "react";
import buildEmployee from "../../../settings/profile/personal/buildEmployee";
import { genderOptions } from "../../../settings/profile/diversityOptions";
import useLightsApiPrivate from "../../../../hooks/useLightsApiPrivate";

const GenderStep = ({ props }) => {
  const { handleNextStep, step, employee, setEmployee } = props;
  const [gender, setGender] = React.useState(employee.gender);

  const { updateEmployee } = useLightsApiPrivate();

  const saveAndNext = async () => {
    const emp = buildEmployee({ ...employee, gender });
    await updateEmployee(employee._id, { ...emp, onboardingStep: "" + step });
    setEmployee({ ...employee, gender });
    handleNextStep();
  };

  props = {
    ...props,
    selected: gender,
    step,
    headline: "Welchem Geschlecht fühlst Du Dich zugehörig?",
    saveAndNext,
  };

  return (
    <OptionStepContainer props={props}>
      <FieldContainer>
        <Dropdown
          value={gender ? gender : "not_applicable"}
          label="Geschlecht"
          menuItems={genderOptions}
          onChange={({ target }) => setGender(target.value)}
        />
      </FieldContainer>
    </OptionStepContainer>
  );
};

export default GenderStep;
