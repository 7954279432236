import CardIntroContent from "./CardIntroContent";
import React from "react";
import { cardTutorialContent } from "./cardTutorialContent";

const CardIntroContentSwitch = ({ step }) => {
  const { img, infoText } = cardTutorialContent[step - 6];
  return <CardIntroContent img={img} infoText={infoText} />;
};

export default CardIntroContentSwitch;
