import * as React from "react";

import FormControl from "@mui/material/FormControl";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";

const Dropdown = ({ dimension, setDimension, options }) => {
  return (
    <FormControl fullWidth>
      <InputLabel id="demo-simple-select-label">Teams</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={dimension}
        label="Level"
        onChange={(e) => setDimension(e.target.value)}
      >
        {options.map((option) => {
          return <MenuItem value={option}>{option}</MenuItem>;
        })}
      </Select>
    </FormControl>
  );
};
export default Dropdown;
