import colors from "../../../common/colors.js";
import styled from "@emotion/styled";

export const TextContainer = styled("div")`
  text-align: center;
  width: 50%;
`;

// TODO move to common
export const Text = styled("p")`
  color: ${colors.midnight};
`;
