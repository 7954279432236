import { Checkbox } from "@mui/material";
import { CheckboxContainer } from "./styles";
import OptionStepContainer from "../OptionStepContainer";
import React from "react";
import Text from "../../../common/Text";
import buildEmployee from "../../../settings/profile/personal/buildEmployee";
import useLightsApiPrivate from "../../../../hooks/useLightsApiPrivate";

const ParentalLeaveStep = ({ props }) => {
  const { handleNextStep, step, employee, setEmployee } = props;
  const [hadParentingTime, setHadParentingTime] = React.useState(
    employee.hadParentingTime
  );

  const { updateEmployee } = useLightsApiPrivate();

  const saveAndNext = async () => {
    const emp = buildEmployee({ ...employee, hadParentingTime });

    await updateEmployee(employee._id, { ...emp, onboardingStep: "" + step });
    setEmployee({ ...employee, hadParentingTime });
    handleNextStep();
  };

  props = {
    ...props,
    selected: true,
    step,
    headline: "Warst Du in dieser Organisation in Elternzeit?",
    saveAndNext,
  };

  return (
    <OptionStepContainer props={props}>
      <CheckboxContainer>
        <Checkbox
          checked={hadParentingTime}
          onChange={({ target }) => {
            setHadParentingTime(target.checked);
          }}
        />
        <Text>{"Ja, war ich"}</Text>
      </CheckboxContainer>
    </OptionStepContainer>
  );
};

export default ParentalLeaveStep;
