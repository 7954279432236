import colors from "../../../common/colors";
import styled from "@emotion/styled";

/*Container*/
export const Container = styled("div")`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: start;
  &.s {
    text-align: center;
    flex-direction: column;
  }
`;

/*Attributes for Circle representing 'Step'*/
export const Step = styled("div")`
  position: flex;
  margin-right: 10px;
  border-radius: 50%;
  height: 35px;
  min-width: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${colors.lavender};
  &.s {
    margin-right: 0px;
  }
`;

/*Attributes for Number in Circle 'Steps' */
export const Heading = styled("h1")`
  font-size: 20px;
  color: ${colors.white};
  font-weight: 500;
  text-align: center;
`;

/*Attributes for 'return'-Button*/
export const BackButtonContainer = styled("div")`
  display: flex;
  margin-right: 10px;
  margin-left: 10px;
  cursor: pointer;
  &.s {
    display: none;
  }
`;
