import styled from "@emotion/styled";

export const Container = styled("div")`
  margin-top: -100px;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 200px;
  height: 300px;
  justify-content: center;
`;

export const Header = styled("div")`
  display: flex;
  flex-direction: column;
  height: 10px;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
`;
