import Modal from "react-modal";
import ModalContent from "./ModalContent";
import React from "react";
import { modalStyle } from "./styles";
import useRole from "../../../hooks/useRole";

const RoleModal = ({ isOpen }) => {
  const { role } = useRole();

  const heading =
    role === "survee"
      ? "Möchtest Du wirklich zur Admin-Ansicht wechseln?"
      : "Möchtest Du wirklich zur Survee-Ansicht wechseln?";

  const toRole = role === "survee" ? "admin" : "survee";

  return (
    <Modal isOpen={isOpen} style={modalStyle} contentLabel="Example Modal">
      <ModalContent heading={heading} toRole={toRole} />
    </Modal>
  );
};

export default RoleModal;
