const diversityContent = {
  birthdate: "Alter",
  gender: "Geschlecht",
  sexuality: "Sexualität",
  ethnicity: "Ethnizität",
  disabilities: "Behinderung",
  religion: "Religion",
  social_background: "Soziale Herkunft",
};

module.exports = { diversityContent };
