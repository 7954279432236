import { ButtonContainer, CloseIcon } from "./styles";

import { Button } from "@mui/material";
import Modal from "react-modal";
import React from "react";
import { modalStyle } from "../../../../../common/modalStyle";
import { useAlert } from "react-alert";
import useLightsApiPrivate from "../../../../../../hooks/useLightsApiPrivate";

const ActionModal = ({
  isModalOpen,
  closeModal,
  actionId,
  setIsSupportRequested,
}) => {
  const alert = useAlert();

  const { sendActionRequest } = useLightsApiPrivate();

  const sendRequest = () => {
    alert.success("Supportanfrage erfolgreich gesendet!");

    setIsSupportRequested(true);
    sendActionRequest(actionId);
    closeModal();
  };

  return (
    <Modal
      isOpen={isModalOpen}
      onRequestClose={closeModal}
      style={modalStyle}
      contentLabel="Actions Modal"
    >
      <CloseIcon onClick={closeModal} />

      <p>
        Lass uns in einem unverbindlichen Call darüber sprechen, wie wir Dich
        bei der Umsetzung der Maßnahme unterstützen können.
      </p>
      <ButtonContainer>
        <Button onClick={closeModal} variant="outlined">
          Abbrechen
        </Button>
        <Button variant="confirm" onClick={sendRequest}>
          Anfrage senden
        </Button>
      </ButtonContainer>
    </Modal>
  );
};

export default ActionModal;
